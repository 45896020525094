<template>
  <div class="col-lg-3 right-aside-wrap">
    <aside class="right-aside">
      <input type="text" placeholder="Aratacağınız kelimeleri giriniz." />
      <div class="exchange-box">
        <span class="amount">Miktar</span>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <span class="currency-img"></span>
          <select id="currency" v-model="toCurrency">
            <option v-for="(item, index) in currencies" :key="index" :value="item.id" :selected="item.code == 'TRY'">{{
              item.code }}</option>
          </select>
          <input class="currency-input" type="number" placeholder="1000.00" v-model="toCurrencyAmount" />
        </div>
      </div>

      <span @click="reverse" class="exchange-icon d-flex align-items-center justify-content-center">
        <img src="@/assets/images/icons/exchange.svg" alt="exchange-icon" />
      </span>

      <div class="exchange-box converted">
        <span class="amount">Dönüştürülen Tutar</span>
        <div class="d-flex align-items-center justify-content-center mb-4">
          <span class="currency-img"></span>
          <select id="currency" v-model="fromCurrency">
            <option v-for="(item, index) in currencies" :key="index" :value="item.id" :selected="item.code == 'USD'">{{
              item.code }}</option>
          </select>
          <input class="currency-input" type="number" placeholder="736.70" v-model="fromCurrencyAmount" />
        </div>
      </div>

      <div class="events-box">
        <div class="events-button d-flex align-items-center">
          <img src="@/assets/images/icons/chexk-square.svg" alt="chexk" />
          <span class="current-time">19.09.2023 (Bugün)</span>
        </div>
        <div class="events-info">
          <div class="info">
            <span class="event">Oturum Açıldı</span>
            <span class="time">18 Eylül, 2023, 4:23</span>
          </div>
        </div>
      </div>
      <div class="past-event-box">
        <div class="past-events-info position-relative">
          <span class="past-time" style="margin-top: -10px; margin-left: 52px; margin-bottom: 10px">Dün</span>
          <div class="info">
            <span class="event">Oturum Açıldı</span>
            <span class="time">18 Eylül, 2023, 4:23</span>
          </div>
        </div>
        <div
          class="events-button past-event-button past-event position-relative d-flex align-items-center justify-content-center">
          <span class="current-time">Geçmiş İşlemleri Göster</span>
        </div>
      </div>
    </aside>
  </div>
</template>
<script setup lang="ts">
import { handleApiError } from '@/helpers/errorHandling';
import { getCurrencies } from '@/services/UtilsService';
import { Currency } from '@/types/UtilsTypes';
import { defineComponent, onMounted, ref, watch } from 'vue';

const currencies = ref<Currency[]>([]);

const toCurrency = ref(1);
const toCurrencyAmount = ref(0);
const fromCurrency = ref(2);
const fromCurrencyAmount = ref(0);

watch([toCurrency, toCurrencyAmount, fromCurrency, fromCurrencyAmount], () => {
  exchange();
})

const exchange = () => {
  const exchangeRate = currencies.value.find(currency => currency.id === fromCurrency.value)?.exchange_rate;
  if (exchangeRate) {
    fromCurrencyAmount.value = toCurrencyAmount.value * exchangeRate;
  }
}

const reverse = () => {
  const temp = toCurrency.value;
  toCurrency.value = fromCurrency.value;
  fromCurrency.value = temp;
}

const fetchCurrencies = async () => {
  try {
    const response = await getCurrencies();
    currencies.value = response.data;
  } catch (error) {
    handleApiError(error);
  }
}

onMounted(async () => {
  await fetchCurrencies();
})

defineComponent({
  name: 'RightSideComponent',
})
</script>